/* globals vars */

:root {
    --blue: #1e90ff;
    --white: #ffffff;

    --headerHeight: 4rem;
    --headerHeight320: 4rem;
    --headerHeight480: 4rem;
    --headerHeight768: 4rem;
    --headerHeight992: 4rem;
    --headerHeight1200: 4rem;

    --padding: 1rem;
    --padding320: 2rem;
    --padding480: 2rem;
    --padding768: 2rem;
    --padding992: 2rem;
    --padding1200: 2rem;

    --titleSectionHeight: 2rem;
    --titleSectionHeight320: 2rem;
    --titleSectionHeight480: 2rem;
    --titleSectionHeight768: 3rem;
    --titleSectionHeight992: 3rem;
    --titleSectionHeight1200: 3rem;

    --buttonProjectsHeight: 3rem;
    --buttonProjectsHeight320: 2rem;
    --buttonProjectsHeight480: 2rem;
    --buttonProjectsHeight768: 2rem;
    --buttonProjectsHeight992: 2rem;
    --buttonProjectsHeight1200: 2rem;

    --fontSize: 0.5em;
    --fontSize320: 0.6em;
    --fontSize480: 0.7em;
    --fontSize768: 0.8em;
    --fontSize992: 0.9em;
    --fontSize1200: 1em;
    --fontSize1400: 1.1em;
    --fontSize1600: 1.2em;
    --fontSize1900: 1.3em;
}

html {
    background-color: #000000;
}

body {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: var(--fontSize);
    background-color: #0F0F0F;
}

.App {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
}

.appFadeIn {
    animation: fadeIn 1s ease-out forwards;
}

.content {
    height: 100%;
    padding-left: var(--padding);
    padding-right: var(--padding);
    padding-top: var(--headerHeight);
}

.titleSection {
    height: var(--titleSectionHeight);
    display: flex;
    align-items: center;
}

/* responsive */

@media (min-width: 320px) {
    body {
        font-size: var(--fontSize320);
    }

    .titleSection {
        height: var(--titleSectionHeight320);
    }
}

@media (min-width: 480px) {
    body {
        font-size: var(--fontSize480);
    }

    .titleSection {
        height: var(--titleSectionHeight480);
    }
}

@media (min-width: 768px) {
    body {
        font-size: var(--fontSize768);
    }

    .titleSection {
        height: var(--titleSectionHeight768);
    }
}

@media (min-width: 992px) {
    body {
        font-size: var(--fontSize992);
    }

    .titleSection {
        height: var(--titleSectionHeight992);
    }

    .content {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media (min-width: 1200px) {
    body {
        font-size: var(--fontSize1200);
    }

    .titleSection {
        height: var(--titleSectionHeight1200);
    }

    .content {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media (min-width: 1200px) {
    body {
        font-size: var(--fontSize1200);
    }

    .titleSection {
        height: var(--titleSectionHeight1200);
    }

    .content {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media screen and (min-width: 1400px) {
    body {
        font-size: var(--fontSize1400);
    }

    .titleSection {
        height: var(--titleSectionHeight1400);
    }

}
@media screen and (min-width: 1600px) {
    body {
        font-size: var(--fontSize1600);
    }
}
@media screen and (min-width: 1900px) {
    body {
        font-size: var(--fontSize1900);
    }
}

/* remove buttons css style */

button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

/* remove link css style */

a {
    color: #ffffff;
    text-decoration: none; /* no underline */
}

/* animations */

@keyframes slide {
    0% {
        transform:translateX(-20%);
    }
    100% {
        transform:translateX(20%);
    }
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        scale: 0.95;
    }
    to {
        opacity: 1;
        scale: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideTop {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.skills {
    height: 100%;
    backdrop-filter: hue-rotate(50deg);

    min-height: 100vh;
}

.skillsContent {
    display: flex;
    flex-wrap:wrap;
}

.item {
    padding-bottom: var(--padding);
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* responsive */

@media (min-width: 320px) {
}

@media (min-width: 480px) {

}

@media (min-width: 768px) {
    .skillsContent {
        height: calc(100vh - var(--titleSectionHeight) - var(--headerHeight));
    }

    .item {
        width:calc(100% / 4);
        font-size: calc(var(--fontSize768) - 0.1em);
        height: 50%;
        padding-right: 1rem;
    }
}

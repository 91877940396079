.contact {
    height: 100vh;
    backdrop-filter: hue-rotate(200deg);
}

.contactContent {
    height: calc(100% - var(--headerHeight));
    display: flex;
    flex-wrap:wrap;
}

.contactItem {
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactItem > * {
    margin-bottom: 1rem;
}

.socialLogoWrapper {
    display: flex;
    gap: 0.5rem;
}

.socialLogo {
    font-size: calc(var(--fontSize) + 2em);
}


.stickyHeader {
    height: var(--headerHeight);
    padding-left: var(--padding);
    padding-right: var(--padding);
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    animation: slideDown 0.5s ease-out forwards;
}

.stickyHeaderMobile {
    height: var(--headerHeight);
    padding-left: var(--padding);
    padding-right: var(--padding);
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    animation: slideDown 0.5s ease-out forwards;
}

.navStickyHeader {
    display: flex;
    gap: 1.2rem;
    font-size: calc(var(--fontSize768) + 0.15em);
    margin-top: 0.25rem;
}

.homeButton {
    bottom: var(--padding);
    right: var(--padding);
    position: fixed;
    background-color: #000000;
    animation: slideTop 0.5s ease-out forwards;
    z-index: 10;
    font-size: calc(var(--fontSize) + 1em);
}

/* responsive */

@media (min-width: 992px) {
    .stickyHeader {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media (min-width: 1200px) {
    .stickyHeader {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

/* background */

.bg {
    animation:slide 20s ease-in-out infinite alternate;
    background-image: linear-gradient(-45deg, #000000 50%, rgb(59, 41, 0) 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
}

.bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 21s;
}

.bg3 {
    animation-duration: 22s;
}

.home {
    height: 100vh;
}

.homeLayout {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeContent {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin-top: -8rem;
}

.homeTypeWriter {
    color: grey;
}

.scrollDownAnimation {
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scrollDownAnimation span{
    display: block;
    width: 24px;
    height: 24px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

.box span:nth-child(2)
{
    animation-delay: -0.2s;
}

.box span:nth-child(3)
{
    animation-delay: -0.4s;
}

@keyframes animate{
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}


.headerDesktop {
    height: var(--headerHeight);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--padding);
    padding-right: var(--padding);
}

.headerMobile {
    height: var(--headerHeight);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--padding);
    padding-right: var(--padding);
}

.navHeader {
    display: flex;
    gap: 1.2rem;
    font-size: calc(var(--fontSize768) + 0.15em);
    margin-top: 0.25rem;
}

.hamburger {
    font-size: calc(var(--fontSize) + 2em);
}

/* responsive */

/* responsive */

@media (min-width: 992px) {
    .headerDesktop {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media (min-width: 1200px) {
    .headerDesktop {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

.work {
    height: 100%;
    min-height: 100vh;
}

.gridProjects {
    height: 100%;
    display: flex;
    flex-wrap:wrap;
}

.buttonProjects {
    height: calc(var(--buttonProjectsHeight) + 2rem);
}

.buttonProjects > * {
    font-size: calc(var(--fontSize) + 0.95em);
    margin-right: var(--padding);
    padding: calc(var(--padding) / 2);
}

.gridProjects h4 {
    margin-bottom: 0.3rem;
}

.selected {
    background-color: rgba(0, 0, 0, 1);
}

.itemProject {
    font-size: calc(var(--fontSize) + 1em);
    padding-bottom: var(--padding);
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.itemProject:hover {
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.05));
    background-size: 200% 200%;
    animation: gradient 15s ease infinite;
}

.itemProjectDisabled:hover {
    background: unset;
}

/* responsive */

@media (min-width: 768px) {
    .itemProject {
        width: 25%;
        font-size: calc(var(--fontSize) + 0.6em);
    }

    .gridProjects {
        height: calc(100vh - 2rem - var(--headerHeight) - var(--titleSectionHeight1200) - var(--buttonProjectsHeight))
    }
}

.experience {
    height: 100%;
    min-height: 100vh;
    backdrop-filter: hue-rotate(150deg);
}

.experienceWrapper {
    height: 100%;
    min-height: calc(100vh - var(--titleSectionHeight1200));
}

.experienceContent {
    display: flex;
    flex-wrap:wrap;
    height: 100%;
    min-height: calc(100vh - var(--titleSectionHeight1200) - var(--headerHeight));
}

.experienceItem {
    width:100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.experienceItem:nth-child(odd) {
    align-items: center;
}

.experienceItem:nth-child(even) {
    font-size: calc(var(--fontSize) * 2);
}

.experienceItem > * {
    margin-bottom: var(--padding);
}

.informationItem {
    color: grey;
}

/* responsive */

@media (min-width: 320px) {
    .experienceItem:nth-child(even) {
        font-size: calc(var(--fontSize) * 2);
    }
}

@media (min-width: 992px) {
    .experienceItem:nth-child(even) {
        font-size: calc(var(--fontSize) * 1.5);
    }

    .experienceContent {
        flex-wrap: nowrap;
    }
}
